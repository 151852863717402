import "./styles.scss";

import React from "react";

const BusinessCard = ({ details }) => {

    const {businessCardsAddress1, businessCardsAddress2, businessCardsEmail, businessCardsImg, businessCardsName, businessCardsPosition, businessCardsTel} = details;

    return (
      <div className="business-card">
        <img src={businessCardsImg.sourceUrl} alt={businessCardsImg.altText} />
        <div className="business-card__content">
            <div className="business-card__name">{businessCardsName}</div>
            <div className="business-card__position">{businessCardsPosition}</div>
            <div className="business-card__others">{businessCardsAddress1}</div>
            <div className="business-card__others">{businessCardsAddress2}</div>
            <a href={`tel:${businessCardsTel}`}>
              <div className="business-card__others">{businessCardsTel}</div>
            </a>
            <a href={`mailto:${businessCardsEmail}`}>
              <div className="business-card__others">{businessCardsEmail}</div>
            </a>
        </div>
      </div>
  );
};

export default BusinessCard;