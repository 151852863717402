import "./styles.scss";

import React, { useState } from "react";
import { navigate } from "gatsby";
import axios from "axios";

const ContactForm = () => {
	const goToThankYouPage = () => {
		navigate("/thank-you/");
	};

	const formID = "394";
	const [send, setSend] = useState(false);
	const [success, setSuccess] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [legalFirst, setLegalFirst] = useState(false);

	const resetForm = () => {
		setSend(false);
		setName("");
		setEmail("");
		setPhone("");
		setSubject("");
		setMessage("");
		setLegalFirst(false);
	};

	const formSubmit = (e) => {
		e.preventDefault();
		setSend(true);

		let formData = new FormData();

		formData.set("firstName", name);
		formData.set("email", email);
		formData.set("phone", phone);
		formData.set("subject", subject);
		formData.set("message", message);
		formData.set("legalFirst", legalFirst);

		axios({
			method: "post",
			url: `https://uchmanskipl.jdmsite.com/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
			data: formData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(() => {
				console.log("Submit success");
				setSuccess("true");
				resetForm();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<form onSubmit={formSubmit} className="contact-form">
			<div className="container">
				<div className="row">
					<div className="col-sm-6 contact-form__block">
						<input
							className="contact-form__input"
							name="fullName"
							id="fullName"
							type="text"
							placeholder="Wpisz imię i nazwisko"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<label
							htmlFor="fullName"
							className="contact-form__label"
						>
							Imię i nazwisko
						</label>
					</div>
					<div className="col-sm-6 contact-form__block">
						<input
							className="contact-form__input"
							name="email"
							id="email"
							type="email"
							placeholder="Wpisz adres e-mail"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
						<label htmlFor="email" className="contact-form__label">
							Adres e-mail*
						</label>
					</div>
					<div className="col-sm-6 contact-form__block">
						<input
							className="contact-form__input"
							name="phone"
							id="phone"
							type="phone"
							placeholder="Nr telefonu"
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
						/>
						<label htmlFor="phone" className="contact-form__label">
							+48
						</label>
					</div>
					<div className="col-sm-6 contact-form__block">
						<select
							className="contact-form__input contact-form__select"
							name="subject"
							value={subject}
							onChange={(e) => setSubject(e.target.value)}
						>
							<option value="" disabled>
								Wybierz temat rozmowy
							</option>
							<option value="Temat 2">Temat 2</option>
							<option value="Temat 3">Temat 3</option>
						</select>
						<label
							htmlFor="subject"
							className="contact-form__label"
						>
							Temat
						</label>
					</div>
					<div className="col-12 contact-form__block">
						<textarea
							className="contact-form__input contact-form__textarea"
							name="message"
							id="message"
							type="message"
							placeholder="Wpisz tutaj wiadomość"
							rows="4"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
						<label
							htmlFor="message"
							className="contact-form__label"
						>
							Wiadomość
						</label>
					</div>
					<div className="col-12 contact-form__block">
						<label className="contact-form__checkbox__wrapper">
							<input
								className="contact-form__checkbox"
								type="checkbox"
								name="legalFirst"
								checked={legalFirst}
								onChange={() => setLegalFirst(!legalFirst)}
								required
							/>
							<span className="contact-form__acceptance-text">
								*Wyrażam zgodę na przetwarzanie moich danych
								osobowych w celu otrzymania odpowiedzi na moją
								wiadomość (…)
							</span>
						</label>
					</div>
					<div className="col-12">
						{success ? (
							<p className="contact-form__success">
								Wiadomość została wysłana!
							</p>
						) : (
							<button
								className="btn-main btn-main--black"
								type="submit"
								disabled={!legalFirst}
								onClick={() => goToThankYouPage()}
							>
								{send === true
									? "Wysyłanie.."
									: "Wyślij wiadomość"}
							</button>
						)}
					</div>
				</div>
			</div>
		</form>
	);
};

export default ContactForm;
