import "./styles.scss";

import React from "react";

const ThemeImage = ({ image }) => {

    return (
      <div className="theme-image">
        <img
          className="theme-image__img"
          src={image.sourceUrl}
          alt={image.altText}
        />
      </div>
  );
};

export default ThemeImage;