import "styles/pages/contact.scss";

import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import ContactForm from "components/ContactForm";
import ThemeImage from "components/ThemeImage";
import BusinessCard from "components/BusinessCard";
import ImagesBar from "components/ImagesBar";

const Contact = ({ data }) => {
	const header_setup = {
		logo: "logo_black.svg",
		fontColor: "#000000",
		navbarColor: "#ffffff",
	};

	const seo = data.pageContact?.nodes[0]?.seo;

	const contact_page_data = data.pageContact?.nodes[0]?.pageContact;

	return (
		<Layout seo={seo} header_setup={header_setup}>
			<section className="contact">
				<div className="container">
					<div className="section-title">
						{contact_page_data.contactTitle}
					</div>
					<div className="row contact__row">
						<div className="col-lg-6 order-2 order-lg-1 mt-xxl-4">
							<ContactForm />
						</div>
						<div className="col-lg-6 order-1 order-lg-2">
							<ThemeImage image={contact_page_data.contactImg} />
						</div>
					</div>
				</div>

				<div className="contact__business-card">
					<div className="container">
						<div className="row contact__row">
							<div className="col-md-6 mb-5 mb-md-0">
								<BusinessCard
									details={contact_page_data.businessCards[0]}
								/>
							</div>
							<div className="col-md-6">
								<BusinessCard
									details={contact_page_data.businessCards[1]}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			<ImagesBar images={contact_page_data.contactGallery} />
		</Layout>
	);
};

export const query = graphql`
	{
		pageContact: allWpPage(filter: { databaseId: { eq: 44 } }) {
			nodes {
				pageContact {
					contactTitle
					contactImg {
						altText
						sourceUrl
					}
					contactGallery {
						galleryImg {
							altText
							sourceUrl
						}
					}
					businessCards {
						businessCardsAddress1
						businessCardsAddress2
						businessCardsEmail
						businessCardsName
						businessCardsPosition
						businessCardsTel
						businessCardsImg {
							altText
							sourceUrl
						}
					}
				}
				seo {
					title
					metaDesc
				}
			}
		}
	}
`;

export default Contact;
